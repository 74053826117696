export default class AvailabilityAction {
  constructor(callback) {
    this._callback = callback;
  }

  getCallback() {
    return this._callback;
  }

  callback() {
    return this._callback();
  }
}
