import { ICONS } from '@emobg/web-components';

export const FAVOURITE_LOCATION_DATA = {
  custom: {
    label: 'modal.preferredLocations.custom',
    icon: ICONS.heart,
  },
  home: {
    label: 'modal.preferredLocations.home',
    icon: ICONS.home,
  },
  work: {
    label: 'modal.preferredLocations.work',
    icon: ICONS.company,
  },
};
