<template>
  <BookingTypeSelectorComponent
    data-test-id="booking-booking_options-booking_type_icon"
    :options="bookingTypeOptions"
    :active="activeBookingType"
    @optionClicked="changeBookingType"
  />
</template>

<script>
import get from 'lodash/get';
import map from 'lodash/map';

// Mixins
import BookingType from '@/mixins/BookingType';
import EventHandler from '@/mixins/EventHandler';
import SegmentMixin from '@/mixins/Segment';

// Constants
import { BOOKING_TYPES } from '@/constants/bookingTypes';
import { SEGMENT_EVENTS } from '@/vue/constants';
import BookingTypeSelectorComponent from './BookingTypeSelectorComponent/BookingTypeSelectorComponent';

export default {

  components: {
    BookingTypeSelectorComponent,
  },

  mixins: [
    BookingType,
    EventHandler,
    SegmentMixin,
  ],

  computed: {
    bookingTypeOptions() {
      return map(this.bookingTypeCollection, bookingType => ({
        title: bookingType.name,
        text: bookingType.description,
        icon: get(this.bookingTypeIcons, bookingType.code),
        bookingType,
      }));
    },
    activeBookingType() {
      return {
        title: this.currentBookingType.name,
        text: this.currentBookingType.description,
        icon: get(this.bookingTypeIcons, this.currentBookingType.code),
        bookingType: this.currentBookingType,
      };
    },
  },

  created() {
    this.bookingTypeIcons = {
      [BOOKING_TYPES.carsharing]: this.ICONS.time,
      [BOOKING_TYPES.longDistance]: this.ICONS.calendar,
      [BOOKING_TYPES.intervention]: this.ICONS.intervention,
    };
  },

  methods: {
    changeBookingType(bookingTypeOption) {
      this.trackSegment({
        name: SEGMENT_EVENTS.BOOKING_TYPE_SELECTED,
        data: {
          booking_type_selected: bookingTypeOption.bookingType,
        },
      });
      this.eventHandler.$emit(this.events.CHANGE_BOOKING_TYPE, bookingTypeOption.bookingType);
    },
  },
};
</script>
