<template>
  <article class="Vehicle p-3 mb-3 emobg-background-color-white emobg-shadow-s">
    <!-- Image about vehicle -->
    <figure class="Vehicle">
      <img
        :src="vehicleCategory.category_image"
        alt="Vehicle image"
        width="175px"
        class="Vehicle mb-2"
      >
    </figure>

    <!-- Info about vehicle -->
    <div class="Vehicle">
      <div class="Vehicle">
        <div class="Vehicle">
          {{ vehicleCategory.name }}
        </div>
      </div>
    </div>

    <!-- Links -->
    <div
      v-if="!isUnpriced"
      class="Vehicle__links"
    >
      <!-- Show or hide details in Home / My bookings page -->
      <span class="emobg-font-small mr-3">
        {{ strShowOrHideDetails }}
      </span>
    </div>

    <div :class="['Vehicle_details my-3', slideToggleClass]">
      <!-- Show models of the category -->
      <div
        v-if="vehicleCategory.models && vehicleCategory.models.length"
        class="Vehicle_details_section mb-4"
      >
        <div class="Vehicle_details_header emobg-font-medium mb-3 pb-1">
          {{ $t('new_booking.booking_list.vehicle.vehicle_categories_models') }}
        </div>
        <div class="Vehicle_details_content flex-wrap">
          <div class="Vehicle_details_text w-100">
            <div class="mb-3">
              <div
                v-for="(model, modelIndex) in vehicleCategory.models"
                :key="modelIndex"
              >
                {{ model }}
              </div>
            </div>
          </div>
          <div class="Vehicle_details_text w-100">
            {{ $t('new_booking.booking_list.vehicle.vehicle_categories_assigned_automatically') }}
          </div>
        </div>
      </div>
    </div>
  </article>
</template>

<script>
import get from 'lodash/get';

// Mixins
import EventHandlerMixin from '@/mixins/EventHandler';
import UserDataMixin from '@/mixins/UserData';
import ProfileMixin from '@/mixins/Profile';
import CompanyMixin from '@/mixins/Company';
import BookingTypeMixin from '@/mixins/BookingType';
import SegmentMixin from '@/mixins/Segment';
import CsOperatorMixin from '@/mixins/CSOperator';
// Services
import googleMapsLoader from '@/vue/managers/GoogleMapsLoader';
import Api from '@/vue/api/backoffice';
// Directives
import SlideToggle from '@/directives/SlideToggle';
// Constants
import { BOOKING_TYPES } from '@/constants/bookingTypes';
import { errorNotification } from '@/handlers/errorHandler.const';
import { useNotifications } from '@/composable/App/Notifications/useNotifications';

export default {

  directives: {
    openVehicleDetails: SlideToggle,
  },

  mixins: [
    EventHandlerMixin,
    UserDataMixin,
    ProfileMixin,
    CompanyMixin,
    BookingTypeMixin,
    SegmentMixin,
    CsOperatorMixin,
  ],

  props: {
    location: {
      type: Object,
      require: true,
    },
    vehicleCategory: {
      type: Object,
      required: true,
    },
    mapManager: {
      type: Object,
      required: true,
    },
    expanded: {
      type: Boolean,
    },
  },
  setup() {
    const { notifyError, notifySuccess } = useNotifications();
    return {
      notifyError,
      notifySuccess,
    };
  },
  data() {
    return {
      slideToggleClass: `js-slideToggle-${(Math.random() * 10000).toFixed(0)}`,
      details: false,
      csOperator: null,
    };
  },

  computed: {
    mileageUnit() {
      return this.getCurrentCSOperator.configuration.mileage_symbol;
    },
    isLongDistance() {
      return this.currentBookingType.code === BOOKING_TYPES.longDistance;
    },
    strShowOrHideDetails() {
      if (this.details) {
        return this.$t('new_booking.booking_list.vehicle.hide_availability_details');
      }

      return this.$t('new_booking.booking_list.vehicle.availability_details');
    },
    isUnpriced() {
      return get(this.vehicleCategory, 'tariff.is_unpriced_tariff');
    },
  },

  methods: {

    clickMarker() {
      this.details = !!$(`.${this.slideToggleClass}:visible`).length;

      if (this.details) {
        const google = googleMapsLoader.getInstance();

        const list = this.mapManager.getMarkerList();

        const myMarker
            = list[`location_${this.location.uuid}`]
            || (this.currentBooking && list[`location_${this.currentBooking.uuid}`]);

        if (myMarker && this.mapManager.getLastMarkerSelected() !== myMarker) {
          this.mapManager.showMarker(myMarker);
          google.maps.event.trigger(myMarker, 'click');
        }
      }
    },

    booking() {
      this.eventHandler.$emit(this.events.GO_BOOKING_CONFIRM, {
        vehicleCategory: this.vehicleCategory,
        location: this.location,
      });
    },

    goToParking(e) {
      e.stopPropagation();
      window.open(`https://www.google.com/maps/?q=${this.location.gps_lat},${this.location.gps_lng}`, '_blank');
    },

    openCar(ev) {
      ev.stopPropagation();

      return Api.carsharing.openVehicleDoor(this.bookingUuid)
        .then(() => {
          this.notifySuccess({
            text: this.$t('notifications.open_close_car_message'),
          });
        })
        .catch((error) => {
          this.notifyError(errorNotification(error));
        });
    },

    closeCar(ev) {
      ev.stopPropagation();

      return Api.carsharing.closeVehicleDoor(this.bookingUuid)
        .then(() => {
          this.notifySuccess({
            text: this.$t('notifications.open_close_car_message'),
          });
        })
        .catch((error) => {
          this.notifyError(errorNotification(error));
        });
    },
  },

};

</script>
