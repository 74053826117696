<template>
  <ui-card
    v-if="isCollapsedFiltersOpen"
    :class="[
      'AvailabilityFiltersPanel p-4 border-top border-bottom border-color-ground',
      {
        'AvailabilityFiltersPanel--animated': isCollapsedFiltersOpen,
      },
    ]"
  >
    <div class="MapViewLayout_left__filters-panel d-flex flex-wrap full-height">
      <div>
        <div
          v-for="(filter, index) in collapsedFilters"
          :key="index"
          class="full-width border-bottom-01 border-color-ink-lightest pb-4 mb-4"
        >
          <div class="font-weight-bold mb-3">
            {{ $t(`views.booking.filters.${filter.filterName}`) }}
          </div>
          <div class="d-flex">
            <template v-for="(option, optionIndex) in filter.options">
              <ui-checkbox
                :key="`${filter.filterName}${optionIndex}`"
                :size="SIZES.small"
                :checked="includes(filter.selected, option.internalName)"
                :name="filter.filterName"
                :caption="option.name"
                :value="option.internalName"
                class="d-block mr-3"
                @changevalue="({ detail }) =>
                  includes(filter.selected, detail)
                    ? filter.selected = without(filter.selected, detail)
                    : filter.selected.push(detail)
                "
              />
            </template>
          </div>
        </div>
      </div>
      <div class="full-width d-flex justify-content-end mt-auto">
        <ui-button
          :color="GRAYSCALE.inkLight"
          :face="FACES.text"
          :size="SIZES.small"
          data-test-id="close-filters-panel-button"
          @clickbutton="closePanel"
        >
          {{ $t('buttons.cancel') }}
        </ui-button>
        <ui-button
          :size="SIZES.small"
          data-test-id="apply-filters-button"
          @clickbutton="onClickApplyFilters"
        >
          {{ $t('views.booking.filters.btn_apply_filters') }}
        </ui-button>
      </div>
    </div>
  </ui-card>
</template>

<script>
import isArray from 'lodash/isArray';
import includes from 'lodash/includes';
import without from 'lodash/without';

import { useAvailability } from '@/composable/Availability/useAvailability';
import { useAvailabilityFilter } from '@/composable/Availability/useAvailabilityFilters';
import { useNotifications } from '@/composable/App/Notifications/useNotifications';

export default {
  name: 'AvailabilityFiltersAnimatedPanel',

  setup() {
    const {
      collapsedFilters,
      applyFilters,
      openMoreFiltersPanel,
      openAvailabilityFilter,
      isCollapsedFiltersOpen,
    } = useAvailabilityFilter();
    const { getUserAvailability, setAvailabilityResponse } = useAvailability();
    const { notifyError } = useNotifications();
    return {
      getUserAvailability,
      collapsedFilters,
      applyFilters,
      setAvailabilityResponse,
      openMoreFiltersPanel,
      openAvailabilityFilter,
      isCollapsedFiltersOpen,
      notifyError,
    };
  },
  methods: {
    includes,
    without,
    closePanel() {
      if (this.collapsedFilters && isArray(this.collapsedFilters)) {
        this.collapsedFilters.forEach(filter => {
          // We are simulating that this filter is opened and
          // we want to close it. When the filter is closed
          // the selected items are the same than the applied filters
          this.openAvailabilityFilter({ filter, opened: false });
        });
      }
    },
    onClickApplyFilters() {
      if (this.collapsedFilters && isArray(this.collapsedFilters)) {
        this.collapsedFilters.forEach(filter => {
          this.applyFilters(filter);
        });

        this.setAvailabilityResponse(null);

        try {
          this.getUserAvailability();

          this.openMoreFiltersPanel(false);
        } catch (error) {
          this.notifyError({
            text: error.message,
            textAction: 'Ok',
          });
        }
      }
    },
  },
};

</script>
