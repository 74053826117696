import isNil from 'lodash/isNil';
import debounce from 'lodash/debounce';
import reject from 'lodash/reject';
import isNumber from 'lodash/isNumber';
import each from 'lodash/each';
import find from 'lodash/find';
import remove from 'lodash/remove';
import delay from 'lodash/delay';

import $ from 'jquery';
import { DELAY, LOG_TYPE, logger } from '@emobg/web-utils';

$.fn.extend({
  isVisibleOnViewport: function fn(fullyInView) {
    const element = this;
    const isFullyInView = !!fullyInView;

    const $window = $(window);
    const $element = $(element);

    // If we can not use Window and the element
    // with jQuery, directly we will suppose that
    // the element is visible
    if (isNil($window) || isNil($element)) {
      return false;
    }

    const pageTop = (() => {
      const num = $window.scrollTop();

      if (!isNumber(num)) {
        return undefined;
      }

      return num;
    })();

    const pageBottom = (() => {
      const h = $(window).height();

      if (isNil(pageTop) || isNil(h)) {
        return undefined;
      }

      return pageTop + h;
    })();

    const elementTop = (() => {
      const offset = $(element).offset();
      return isNil(offset) ? undefined : offset.top;
    })();

    const elementBottom = (() => {
      const h = $(element).height();

      if (isNil(elementTop) || isNil(h)) {
        return undefined;
      }

      return elementTop + h;
    })();

    if (isNil(pageTop) || isNil(pageBottom) || isNil(elementTop) || isNil(elementBottom)) {
      return false;
    }

    if (isFullyInView) {
      return ((pageTop < elementTop) && (pageBottom > elementBottom));
    }

    return ((elementTop <= pageBottom) && (elementBottom >= pageTop));
  },
});

export const nodeElements = [];

let isListenerActive = false;

const getRootElement = () => document.getElementById('app');

export const checkNodeVisibility = debounce(() => {
  const invisibleNodes = reject(nodeElements, 'visible');

  each(invisibleNodes, node => {
    if ($(node.selector).isVisibleOnViewport()) {
      node.visible = true;
    }
  });
}, DELAY.short);

export const addNodeElement = selector => {
  const node = find(nodeElements, { selector });

  if (node) {
    remove(nodeElements, { selector });
  }

  nodeElements.push({
    selector,
    visible: false,
  });
};

export const addListener = () => {
  if (!isListenerActive) {
    logger.message('Listen scroll event to check if nodes are visibles', LOG_TYPE.info);
    const root = getRootElement();
    root.addEventListener('scroll', checkNodeVisibility);
    isListenerActive = true;

    delay(checkNodeVisibility, DELAY.medium);
  }
};

export const removeListener = () => {
  logger.message('Unlisten scroll event', LOG_TYPE.info);
  const root = getRootElement();
  root.removeEventListener('scroll', checkNodeVisibility);
  isListenerActive = false;
};

export const isVisible = (nodes, selector) => {
  const node = find(nodes, { selector });

  return node ? !!node.visible : true;
};
