<template>
  <img
    :src="getGeolocationIconByStatus"
    alt=""
    width="16px"
  >
</template>

<script>

// Utils
import { instance as mapMarkerSelector } from '@/services/MapMarkerSelector';

export default {

  data() {
    return {
      status: false,
    };
  },

  computed: {
    getGeolocationIconByStatus() {
      return this.status ? mapMarkerSelector.geoLocationOn() : mapMarkerSelector.geoLocationOff();
    },
  },

  methods: {
    active() {
      this.status = true;
    },

    deactivate() {
      this.status = false;
    },

    isActive() {
      return this.status === true;
    },
  },

};

</script>
