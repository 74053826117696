<template>
  <div class="w-100">
    <div :class="['phTimeline phTimeline-placeholdered mb-0', hasGradient && 'gradient']" />
    <div :class="['phTimeline phTimeline-placeholdered', hasGradient && 'gradient']" />
  </div>
</template>

<script>

export default {
  props: {
    hasGradient: {
      type: Boolean,
      default: false,
    },
  },
};

</script>
