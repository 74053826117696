import { LOG_TYPE, logger } from '@emobg/web-utils';

import googleMapsLoader from '@/vue/managers/GoogleMapsLoader';

function resolveNavigatorGeolocaiton(resolve) {
  const whenGeolocationIsEnabled = position => {
    this.setLatitude(position.coords.latitude);
    this.setLongitude(position.coords.longitude);
    this.setActive(true);

    resolve(this);
  };

  const whenGeolocationIsDisabled = () => {
    logger.message('The geolocation service failed.', LOG_TYPE.warning);
    this.setActive(false);

    resolve(this);
  };

  if (navigator.geolocation) {
    navigator.geolocation.getCurrentPosition(
      whenGeolocationIsEnabled,
      whenGeolocationIsDisabled,
      {
        timeout: 5000,
      },
    );
  } else {
    logger.message('Browser doesn\'t support Geolocation.', LOG_TYPE.warning);
    this.setActive(false);

    resolve(this);
  }
}

export class GeolocationManager {
  constructor() {
    this.lat = null;
    this.long = null;
    this.active = false;
  }

  getLatitude() {
    return this.lat;
  }

  getLongitude() {
    return this.long;
  }

  isActive() {
    return this.active;
  }

  setActive(val) {
    this.active = val;
  }

  setLatitude(value) {
    this.lat = parseFloat(value);
  }

  setLongitude(value) {
    this.long = parseFloat(value);
  }

  setLatitudeAndLongitude(lat, long) {
    this.setLatitude(lat);
    this.setLongitude(long);
  }

  getNavigatorGeolocation() {
    return new Promise(resolveNavigatorGeolocaiton.bind(this));
  }

  isCloseOfMyPosition(lat, long) {
    const google = googleMapsLoader.getInstance();

    const myPosition = new google.maps.LatLng(this.getLatitude(), this.getLongitude());
    const point = new google.maps.LatLng(lat, long);
    const distance = google.maps.geometry.spherical.computeDistanceBetween(point, myPosition);

    return distance <= 100;
  }
}

export const geolocationManager = new GeolocationManager();
