<template>
  <div class="CostAllocation">
    <ui-validate @status="({ detail }) => $emit('on:form-status', detail)">
      <ui-text-input
        v-for="costAllocation in filter(currentProfileCostAllocations, ['inputType', INPUT_TYPE.text])"
        :key="costAllocation.name"
        v-validate.blurinput="costAllocation.validation"
        v-bind="costAllocation.props"
        :options.prop="costAllocation.options"
        :data-test-id="costAllocation.dataTestId"
        :placeholder="costAllocation.name"
        data-masked
        class="text-wrap my-2"
        @changevalue="({ detail }) => updateCostAllocation(detail, costAllocation.name)"
      />
      <div
        v-for="costAllocation in filter(currentProfileCostAllocations, ['inputType', INPUT_TYPE.select])"
        :key="costAllocation.name"
        class="my-4"
      >
        <ui-select
          v-validate.select="costAllocation.validation"
          v-bind="costAllocation.props"
          :value="[]"
          :options.prop="costAllocation.options"
          :data-test-id="costAllocation.dataTestId"
          :placeholder="costAllocation.name"
          data-masked
          class="text-wrap py-2"
          @selectoption="({ detail }) => updateCostAllocation(detail, costAllocation.name)"
        />
      </div>
    </ui-validate>
  </div>
</template>

<script>
import find from 'lodash/find';
import findIndex from 'lodash/findIndex';
import filter from 'lodash/filter';
import snakeCase from 'lodash/snakeCase';
import set from 'lodash/set';

import { Validate } from '@emobg/vue-base';
import { getEmitPayload } from '@/helpers/userCompany/costAllocationsHelpers';
import { getCostAllocations } from '@/components/Profile/tabs/CostAllocations/helpers/transformers';

import { INPUT_TYPE } from './const/costAllocation.const';
export default {
  name: 'CostAllocation',
  directives: {
    Validate,
  },
  props: {
    costsList: {
      type: Array,
      default: () => [],
    },
    selectedCosts: {
      type: Array,
      default: () => [],
    },
    isDisabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      formStatus: { isValid: false },
      currentProfileCostAllocations: [],
    };
  },
  created() {
    this.INPUT_TYPE = INPUT_TYPE;

    this.currentProfileCostAllocations = getCostAllocations({
      costAllocations: this.costsList,
    });
  },
  methods: {
    snakeCase,
    getEmitPayload,
    filter,
    updateCostAllocation(newValue, name) {
      const costAllocationIndex = findIndex(this.currentProfileCostAllocations, ['name', name]);
      set(this.currentProfileCostAllocations, `${costAllocationIndex}.props.value`, newValue);

      this.$emit('update:cost', getEmitPayload(find(this.currentProfileCostAllocations, ['name', name]), newValue));
    },
  },
};
</script>
