var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.isCollapsedFiltersOpen)?_c('ui-card',{class:[
    'AvailabilityFiltersPanel p-4 border-top border-bottom border-color-ground',
    {
      'AvailabilityFiltersPanel--animated': _vm.isCollapsedFiltersOpen,
    },
  ]},[_c('div',{staticClass:"MapViewLayout_left__filters-panel d-flex flex-wrap full-height"},[_c('div',_vm._l((_vm.collapsedFilters),function(filter,index){return _c('div',{key:index,staticClass:"full-width border-bottom-01 border-color-ink-lightest pb-4 mb-4"},[_c('div',{staticClass:"font-weight-bold mb-3"},[_vm._v(" "+_vm._s(_vm.$t(`views.booking.filters.${filter.filterName}`))+" ")]),_c('div',{staticClass:"d-flex"},[_vm._l((filter.options),function(option,optionIndex){return [_c('ui-checkbox',{key:`${filter.filterName}${optionIndex}`,staticClass:"d-block mr-3",attrs:{"size":_vm.SIZES.small,"checked":_vm.includes(filter.selected, option.internalName),"name":filter.filterName,"caption":option.name,"value":option.internalName},on:{"changevalue":({ detail }) =>
                _vm.includes(filter.selected, detail)
                  ? filter.selected = _vm.without(filter.selected, detail)
                  : filter.selected.push(detail)}})]})],2)])}),0),_c('div',{staticClass:"full-width d-flex justify-content-end mt-auto"},[_c('ui-button',{attrs:{"color":_vm.GRAYSCALE.inkLight,"face":_vm.FACES.text,"size":_vm.SIZES.small,"data-test-id":"close-filters-panel-button"},on:{"clickbutton":_vm.closePanel}},[_vm._v(" "+_vm._s(_vm.$t('buttons.cancel'))+" ")]),_c('ui-button',{attrs:{"size":_vm.SIZES.small,"data-test-id":"apply-filters-button"},on:{"clickbutton":_vm.onClickApplyFilters}},[_vm._v(" "+_vm._s(_vm.$t('views.booking.filters.btn_apply_filters'))+" ")])],1)])]):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }