import { genericError, protection } from '@/utils/publicImages';

export function successArgs($t) {
  return {
    title: $t('modal.booking_insurance.success.title'),
    description: '',
    image: protection,
    primaryCallToActionText: $t('buttons.got_it'),
  };
}

export function errorArgs($t, operatorWorkingHours, operatorPhone) {
  const description = `${$t('modal.booking_insurance.error.description')}
    </br>
    <p class="emobg-font-weight-bold">${operatorPhone}</p>
  `;
  return {
    description,
    title: $t('modal.booking_insurance.error.title'),
    primaryCallToActionText: $t('modal.booking_insurance.error.try_again'),
    extraInfo: $t('modal.booking_insurance.error.note', {
      workingHours: operatorWorkingHours,
    }),
    image: genericError,
  };
}
