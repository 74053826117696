import isNil from 'lodash/isNil';
import { ICONS, ICONS_SIZES } from '@emobg/web-components';

const BATTERY_LEVELS = {
  notAvailable: null,
  empty: 0,
  critical: 25,
  medium: 75,
  full: 100,
};

export const isMissingBattery = (batteryLevel, levels = BATTERY_LEVELS) => batteryLevel === levels.notAvailable;

export const isEmptyBattery = (batteryLevel, levels = BATTERY_LEVELS) => batteryLevel === levels.empty;

export const isCriticalLevel = (batteryLevel, lowerLevel, levels = BATTERY_LEVELS) => {
  const critical = isNil(lowerLevel) ? BATTERY_LEVELS.critical : lowerLevel;

  return batteryLevel > levels.empty && batteryLevel <= critical;
};

export const isDangerousLevel = (batteryLevel, lowerLevel, levels = BATTERY_LEVELS) => {
  const isEmptyStatus = isEmptyBattery(batteryLevel, levels);
  const isCriticalStatus = isCriticalLevel(batteryLevel, lowerLevel, levels);

  return isEmptyStatus || isCriticalStatus;
};

export const isMediumLevel = (batteryLevel, criticalLevel, levels = BATTERY_LEVELS) => {
  const critical = isNil(criticalLevel) ? BATTERY_LEVELS.critical : criticalLevel;

  return batteryLevel > critical && batteryLevel < levels.medium;
};

export const isModerateLevel = (batteryLevel, levels = BATTERY_LEVELS) => (batteryLevel >= levels.medium) && (batteryLevel <= levels.full);

export const getIcon = (batteryLevel, criticalLevel) => {
  switch (true) {
    case isMissingBattery(batteryLevel):
      return ICONS.batteryEmpty;
    case isEmptyBattery(batteryLevel):
      return ICONS.battery;
    case isCriticalLevel(batteryLevel, criticalLevel):
      return ICONS.batteryCritical;
    case isMediumLevel(batteryLevel, criticalLevel):
      return ICONS.batteryLow;
    case isModerateLevel(batteryLevel):
      return ICONS.batteryModerate;
    default:
      return null;
  }
};

export const EV_ICON_PROPS = {
  icon: ICONS.electric,
  color: 'electric',
  size: ICONS_SIZES.medium,
};
