var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"d-flex flex-column"},[_c('ui-button-segments',{staticClass:"mb-3",attrs:{"value":_vm.selectedSearchOption,"color":_vm.COLORS.primary,"size":_vm.SIZES.large,"data-test-id":"search_type-toggle"},domProps:{"options":_vm.switcherOptions},on:{"clickbuttonsegment":({ detail }) => _vm.selectedSearchOption = detail}}),(_vm.selectedSearchOption === _vm.searchOptions.station)?_c('ui-select',{key:"station-select",staticClass:"GeocompleteSelect w-100 mb-2",attrs:{"value":_vm.selectedStation,"show-limit":20,"placeholder":_vm.$t('map.select_station_placeholder'),"name":"station","data-test-id":"search_station"},domProps:{"searchbox":{
      placeholder: _vm.$t('map.select_station_placeholder'),
      threshold: 10,
    },"options":_vm.stations.map(({name, uuid}) => ({ label: name, value: uuid, }))},on:{"selectoption":({ detail }) => _vm.emitPlacePrediction(detail)}}):(_vm.selectedSearchOption === _vm.searchOptions.favouriteLocations)?_c('ui-select',{key:"favourite-select",staticClass:"GeocompleteSelect w-100 mb-2",attrs:{"value":_vm.selectedFavourite,"placeholder":_vm.$t('map.favourite_select_placeholder'),"size":_vm.SIZES.large,"name":"favourite","data-test-id":"favourites-select"}},[_c('div',{attrs:{"slot":"content"},slot:"content"},_vm._l((_vm.presetPreferredLocations),function(item){return _c('div',{key:item.value,staticClass:"p-2 d-flex align-items-center justify-content-between emobg-background-color-ground-lighter-hover cursor-pointer"},[_c('div',{staticClass:"w-100 d-flex align-items-center",on:{"click":() => _vm.selectFavouriteLocation(item)}},[_c('ui-icon',{staticClass:"mr-2",attrs:{"icon":_vm.favouriteLocationData[item.type].icon,"size":_vm.ICONS_SIZES.small,"color":item.disabled ? _vm.GRAYSCALE.ink : _vm.COLORS.primary,"data-test-id":"favourite_type-icon"}}),_vm._v(" "+_vm._s(item.label)+" ")],1),_c('ui-button',{attrs:{"face":_vm.FACES.text,"size":_vm.SIZES.small,"color":_vm.GRAYSCALE.ink,"square":"","data-test-id":"edit_favourites_location-button"},on:{"clickbutton":_vm.openPreferredLocations}},[_c('ui-icon',{attrs:{"icon":_vm.ICONS.edit,"size":_vm.ICONS_SIZES.xSmall}})],1)],1)}),0)]):_c('div',[_c('MuiInputLocation',{key:"address-input_location",ref:"inputLocation",attrs:{"name":"geocomplete","google":_vm.google,"default-predictions":_vm.defaultPredictions,"autocomplete-request":{
        types: [],
      },"placeholder":_vm.$t('map.input_search_placeholder'),"error-messages":{
        notSelectedFromList: '',
      },"use-coordinates":"","full-width":"","input-class":"phInput phMap_searchInput p-4 emobg-color-ink-light","data-test-id":"search_address"},model:{value:(_vm.places),callback:function ($$v) {_vm.places=$$v},expression:"places"}}),_c('div',{staticClass:"phMap_geolocationIconWrapper",class:{
        'switcher_margin': _vm.hasSwitcher,
      },on:{"click":function($event){return _vm.$emit('center-user-position')}}},[_c('CurrentPosition',{ref:"currentPosition",staticClass:"cursor-pointer",attrs:{"data-test-id":"user_position_icon-button"}})],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }