<template>
  <div class="d-flex flex-column py-4 px-3">
    <div class="mb-2">
      <template v-if="!hasAnnualInsurance">
        <template v-for="insurance in insuranceList">
          <ui-radio
            :key="insurance.uuid"
            :value="selectedInsurance.uuid"
            :option="insurance.uuid"
            :data-test-id="`insurance-${insurance.internal_name}`"
            :caption="getLabel(insurance)"
            :name="insurance.internal_name"
            :disabled="isDisabled"
            class="d-block emobg-color-ink"
            @changevalue="() => selectedInsurance = insurance"
          />
        </template>
      </template>
      <template v-else>
        <span
          v-for="insurance in insuranceList"
          :key="insurance.uuid"
        >
          {{ getLabel(insurance) }}
        </span>
      </template>
    </div>
    <div class="row">
      <ui-button
        v-bind="fetchButtonSpecs({ buttonType: THEME_BUTTON_TYPES.TERTIARY })"
        data-test-id="insurance_more_info"
        @clickbutton="() => $emit('show-insurance-details')"
      >
        {{ $t('common.more_info') }}
      </ui-button>
    </div>
  </div>
</template>

<script>
import get from 'lodash/get';
import { isAnnualInsurance, isFullInsurance } from '@/helpers/booking/insurancesHelpers';
import { useTheme } from '@/composable/Theme/useTheme';

export default {
  name: 'InsuranceSelectorComponent',

  props: {
    data: {
      type: Object,
      default: () => ({}),
    },
    isDisabled: {
      type: Boolean,
      default: false,
    },
  },
  setup() {
    const { fetchButtonSpecs } = useTheme();

    return { fetchButtonSpecs };
  },

  data() {
    return {
      selectedInsurance: null,
    };
  },

  computed: {
    currentInsurance() {
      return get(this, 'data.current_insurance', {});
    },
    insuranceList() {
      const {
        upsell_possible: upsellPossible,
        upsell_insurances: upsellInsurances,
      } = this.data;

      const isUpsell = upsellPossible && upsellInsurances;
      const insurances = isUpsell ? upsellInsurances : [];

      return [
        this.currentInsurance,
        ...insurances,
      ];
    },

    hasAnnualInsurance() {
      return isAnnualInsurance(this.currentInsurance);
    },
  },

  watch: {
    selectedInsurance(insurance) {
      if (!insurance) {
        return;
      }

      this.$emit('changed', insurance);
    },
  },

  created() {
    this.selectedInsurance = { ...this.currentInsurance };
  },

  methods: {
    getLabel(insurance = {}) {
      const name = isAnnualInsurance(insurance)
        ? this.$t('modal.insurance.annual_insurance_full_covery')
        : insurance.name;

      const price = isFullInsurance(insurance)
        ? ` + ${insurance.price}`
        : '';

      return `${name}${price}`;
    },
  },
};

</script>
