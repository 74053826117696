<template>
  <div class="d-flex flex-column">
    <ui-button-segments
      :value="selectedSearchOption"
      :options.prop="switcherOptions"
      :color="COLORS.primary"
      :size="SIZES.large"
      class="mb-3"
      data-test-id="search_type-toggle"
      @clickbuttonsegment="({ detail }) => selectedSearchOption = detail"
    />
    <ui-select
      v-if="selectedSearchOption === searchOptions.station"
      key="station-select"
      :value="selectedStation"
      :show-limit="20"
      :searchbox.prop="{
        placeholder: $t('map.select_station_placeholder'),
        threshold: 10,
      }"
      :options.prop="stations.map(({name, uuid}) => ({ label: name, value: uuid, }))"
      :placeholder="$t('map.select_station_placeholder')"
      class="GeocompleteSelect w-100 mb-2"
      name="station"
      data-test-id="search_station"
      @selectoption="({ detail }) => emitPlacePrediction(detail)"
    />
    <ui-select
      v-else-if="selectedSearchOption === searchOptions.favouriteLocations"
      key="favourite-select"
      :value="selectedFavourite"
      :placeholder="$t('map.favourite_select_placeholder')"
      :size="SIZES.large"
      class="GeocompleteSelect w-100 mb-2"
      name="favourite"
      data-test-id="favourites-select"
    >
      <div slot="content">
        <div
          v-for="item in presetPreferredLocations"
          :key="item.value"
          class="p-2 d-flex align-items-center justify-content-between emobg-background-color-ground-lighter-hover cursor-pointer"
        >
          <div
            class="w-100 d-flex align-items-center"
            @click="() => selectFavouriteLocation(item)"
          >
            <ui-icon
              :icon="favouriteLocationData[item.type].icon"
              :size="ICONS_SIZES.small"
              :color="item.disabled ? GRAYSCALE.ink : COLORS.primary"
              class="mr-2"
              data-test-id="favourite_type-icon"
            />
            {{ item.label }}
          </div>
          <ui-button
            :face="FACES.text"
            :size="SIZES.small"
            :color="GRAYSCALE.ink"
            square
            data-test-id="edit_favourites_location-button"
            @clickbutton="openPreferredLocations"
          >
            <ui-icon
              :icon="ICONS.edit"
              :size="ICONS_SIZES.xSmall"
            />
          </ui-button>
        </div>
      </div>
    </ui-select>

    <div v-else>
      <MuiInputLocation
        key="address-input_location"
        ref="inputLocation"
        v-model="places"
        name="geocomplete"
        :google="google"
        :default-predictions="defaultPredictions"
        :autocomplete-request="{
          types: [],
        }"
        :placeholder="$t('map.input_search_placeholder')"
        :error-messages="{
          notSelectedFromList: '',
        }"
        use-coordinates
        full-width
        input-class="phInput phMap_searchInput p-4 emobg-color-ink-light"
        data-test-id="search_address"
      />
      <div
        :class="{
          'switcher_margin': hasSwitcher,
        }"
        class="phMap_geolocationIconWrapper"
        @click="$emit('center-user-position')"
      >
        <CurrentPosition
          ref="currentPosition"
          class="cursor-pointer"
          data-test-id="user_position_icon-button"
        />
      </div>
    </div>
  </div>
</template>

<script>
import size from 'lodash/size';
import values from 'lodash/values';
import map from 'lodash/map';
import get from 'lodash/get';
import concat from 'lodash/concat';
import isEmpty from 'lodash/isEmpty';
import isString from 'lodash/isString';
import filter from 'lodash/filter';
import find from 'lodash/find';
import isNumber from 'lodash/isNumber';
import toNumber from 'lodash/toNumber';
import mapValues from 'lodash/mapValues';
import orderBy from 'lodash/orderBy';
import { MuiInputLocation } from '@emobg/motion-ui';
import { GRAYSCALE } from '@emobg/web-components';

import googleMapsLoader from '@/vue/managers/GoogleMapsLoader';
import OperatorStationIcon from '@/assets/images/icons/parking-circle.svg';
import CurrentPosition from '@/vue/components/molecules/common/CurrentPosition/CurrentPosition';

import { instance as mapMarkerSelector } from '@/services/MapMarkerSelector';

import { operatorLocations } from '@/stores/OperatorLocations/OperatorLocationsMapper';
import { currentFavouriteLocations } from '@/stores/FavouriteLocations/FavouriteLocationsMapper';
import { getCurrentCity } from '@/stores/City/CityMapper.js';
import { getLocationsCollection } from '@/stores/Locations/LocationsMapper';
import { getCompanyData } from '@/stores/Company/CompanyMapper';
import { userData } from '@/stores/User/UserData/UserDataMapper';

import { SEGMENT_EVENTS } from '@/vue/constants';
import { useSegment } from '@/composable/Segment/segment';
import { SEARCH_OPTIONS_VALUES } from './constants/options.const';
import { ICONS } from './constants/icons.const';
import { FAVOURITE_LOCATION_DATA } from './constants/favouriteConfig.const';

export default {
  name: 'Geocomplete',

  components: {
    MuiInputLocation,
    CurrentPosition,
  },

  props: {
    preferedLocations: {
      type: Boolean,
      default: false,
    },
    hasSwitcher: {
      type: Boolean,
      default: false,
    },
  },

  setup() {
    const { trackSegment: trackSegmentEvent } = useSegment();

    return { trackSegmentEvent };
  },

  data() {
    return {
      places: {},
      google: googleMapsLoader.getInstance(),
      selectedSearchOption: SEARCH_OPTIONS_VALUES.address,
      selectedStation: null,
      selectedFavourite: null,
    };
  },

  computed: {
    currentFavouriteLocations,
    getCurrentCity,
    getLocationsCollection,
    getCompanyData,
    operatorLocations,
    userData,

    presetPreferredLocations() {
      const preferredLocationsOptions = mapValues(this.currentFavouriteLocations, (preferredLocation, type) => ({
        ...preferredLocation,
        address_street: isEmpty(preferredLocation.address_street)
          ? `${this.$t(FAVOURITE_LOCATION_DATA[type].label)}`
          : preferredLocation.address_street,
        type,
        disabled: isEmpty(preferredLocation.address_street),
      }));

      return orderBy(values(preferredLocationsOptions), ({ description }) => description || '', ['desc']).map(item => ({
        disabled: item.disabled,
        label: item.address_street || this.$t(FAVOURITE_LOCATION_DATA[item.type].label),
        value: item.uuid,
        type: item.type,
      }));
    },
    filledPreferredLocations() {
      return filter(values(this.currentFavouriteLocations), 'address_street');
    },
    knownPlaces() {
      return concat(values(this.filledPreferredLocations), this.stations);
    },
    defaultPredictions() {
      let predictions = [];
      predictions = map(
        this.filledPreferredLocations,
        prediction => {
          const preferredDescription = this.$t(FAVOURITE_LOCATION_DATA[get(prediction, 'type', 'custom')].label);
          const addressStreet = get(prediction, 'address_street', '');

          let description = `<b>${preferredDescription}</b>`;

          if (addressStreet.trim()) {
            description = `${description} <span class="emobg-color-${GRAYSCALE.inkLight}">${addressStreet}</span>`;
          }

          const icon = ICONS[get(prediction, 'type')];

          return {
            ...prediction,
            description,
            icon,
          };
        },
      );

      if (get(this.userData, 'company.dedicated_fleet_cs_operator')) {
        predictions = concat(predictions, this.stations);
      }

      predictions = concat(predictions, values(this.sanitizedLocations));

      return predictions;
    },
    sanitizedLocations() {
      return map(this.getLocationsCollection,
        location => (
          {
            ...location,
            value: location.address || location.name,
            description: location.name || location.description,
            label: location.name || location.address,
            icon: mapMarkerSelector.parkingIcon(),
          }
        ));
    },
    stations() {
      return map(this.operatorLocations,
        location => (
          {
            ...location,
            value: location.address || location.name,
            description: location.name || location.description,
            label: location.name || location.address,
            icon: OperatorStationIcon,
          }
        ));
    },
    switcherOptions() {
      const options = [
        {
          label: this.$t('map.toggle_search_type.address_option'),
          value: SEARCH_OPTIONS_VALUES.address,
          condition: true,
        },
        {
          label: this.$t('map.toggle_search_type.station_option'),
          value: SEARCH_OPTIONS_VALUES.station,
          condition: Boolean(size(this.operatorLocations)) && get(this, 'getCompanyData.company_fleets.dedicated'),
        },
        {
          label: this.$t('map.toggle_search_type.favourite_option'),
          value: SEARCH_OPTIONS_VALUES.favouriteLocations,
          condition: this.preferedLocations,
        },
      ];
      return filter(options, 'condition');
    },
  },

  watch: {
    places: {
      handler(value = {}) {
        if (!isEmpty(value.address)) {
          this.emitPlacePrediction(value);
        }
      },
      deep: true,
    },
  },

  created() {
    this.favouriteLocationData = FAVOURITE_LOCATION_DATA;
    this.searchOptions = SEARCH_OPTIONS_VALUES;
  },

  methods: {
    size,
    getPlaceData(place) {
      let station;
      if (isString(place)) {
        station = this.stations.find(opStation => opStation.name === place || opStation.address === place) || {};
      }
      return isEmpty(station)
        ? place
        : station;
    },
    getPlaceCoordinates(place) {
      return {
        lat: toNumber(get(place, 'location.lat', place.gps_lat)),
        lng: toNumber(get(place, 'location.lng', place.gps_lng)),
      };
    },
    emitPlacePrediction(value, isDisabled = false) {
      if (isDisabled) {
        return;
      }

      const place = isString(value)
        ? find(this.knownPlaces, ['uuid', value])
        : this.getPlaceData(value);

      const coordinates = this.getPlaceCoordinates(place);

      if (!isNumber(coordinates.lat) || !isNumber(coordinates.lng)) {
        return;
      }

      this.$emit('input-geocomplete-place', {
        ...coordinates,
        placeResult: {
          uuid: get(place, 'uuid'),
          address: get(place, 'address'),
          location: { ...coordinates },
        },
      });

      this.trackSearchTypeChange(place);
    },

    openPreferredLocations() {
      this.$emit('open-preferred-location', {});
      this.trackSegmentEvent({
        name: SEGMENT_EVENTS.OPEN_PREFERRED_LOCATIONS,
      });
    },

    trackSearchTypeChange(place) {
      const placeName = place.address || place.description || place.name;
      if (!isEmpty(placeName)) {
        this.trackSegmentEvent({
          name: SEGMENT_EVENTS.CHOOSE_NEW_LOCATION_SEARCH,
          data: {
            new_value: placeName,
            search_type: this.selectedSearchOption,
            ff_value: this.$featureFlag.flags.toggleSearchLocationTypes,
          },
        });
      }
    },

    selectFavouriteLocation(selectedItem) {
      if (!selectedItem.disabled) {
        this.emitPlacePrediction(selectedItem.value, selectedItem.disabled);
        this.selectedFavourite = selectedItem.label;
      }
    },
  },

};

</script>
