import toNumber from 'lodash/toNumber';
import isNull from 'lodash/isNull';
import isNaN from 'lodash/isNaN';

import { DEFAULT_COORDINATES } from '@/constants/map.const';

export const normalizeCoordinates = (latitudeValue, longitudeValue) => {
  const latitude = isNull(latitudeValue) ? NaN : toNumber(latitudeValue);
  const longitude = isNull(longitudeValue) ? NaN : toNumber(longitudeValue);

  return (isNaN(latitude) || isNaN(longitude))
    ? DEFAULT_COORDINATES
    : { latitude, longitude };
};
