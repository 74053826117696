<template>
  <Modal>
    <template
      v-if="location && location.is_geofence"
      slot="header"
    >
      {{ $t('modal.location_details.zone_title') }}
    </template>
    <template
      v-else
      slot="header"
    >
      {{ $t('modal.location_details.title') }}
    </template>

    <template
      v-if="location"
      slot="body"
    >
      <div class="Location__slide">
        <Swipe :swipe="swipe">
          <template v-for="(img, index) in location.images">
            <div
              :key="index"
              class="rd-swipe-item"
            >
              <img
                :src="url(img)"
                alt=""
                class="img-fluid"
              >
            </div>
          </template>
        </Swipe>
      </div>
      <br>
      <h6 class="Location__header emobg-color-ink-light">
        {{ printableAddress(location) }}
      </h6>
      <p class="Location__text">
        {{ location.address }}
      </p>
      <p
        v-if="location.is_geofence"
        v-html="$t('modal.location_details.zone_info')"
      />
      <br>

      <div v-html="compiledDescription(location.description)" />
    </template>
  </Modal>
</template>

<script>
// Utils
import { external } from '@emobg/web-api-client';

import { printableAddress } from '@/helpers/booking/locationHelpers';
// Components
import Modal from '@/templates/Modal/Modal';
// Mixins
import ModalMixin from '@/mixins/Modal';
import BookingTypeMixin from '@/mixins/BookingType';

// Constants
import {
  SEGMENT_ENUMERATE_VALUES,
  SEGMENT_EVENTS,
  SEGMENT_PARAM_NAMES,
} from '@/vue/constants';
import { BOOKING_TYPES } from '@/constants/bookingTypes';

import { useTrackingGTM } from '@/composable/GTM/gtm';
import { useSegment } from '@/composable/Segment/segment';
import { useNotifications } from '@/composable/App/Notifications/useNotifications';

// External packages
const Marked = require('marked');
const Swipe = () => import('vue-slide/vue-slide');

export default {
  components: {
    Modal,
    Swipe,
  },
  mixins: [
    ModalMixin,
    BookingTypeMixin,
  ],

  setup() {
    const { trackPageView } = useTrackingGTM();
    const { trackSegment } = useSegment();
    const { notifyError } = useNotifications();
    return { trackPageView, trackSegment, notifyError };
  },

  data() {
    return {
      location: null,
      mapManager: null,
      loading: true,
      swipe: {
        activeIndex: 0,
        autoplay: 10 ** 20,
      },
    };
  },

  computed: {
    isLongDistance() {
      return this.currentBookingType.code === BOOKING_TYPES.longDistance;
    },
  },
  async created() {
    const [location, mapManager] = this.getModalParams();
    this.mapManager = mapManager;
    try {
      const data = await external.fleetLocations.getLocationDetails(location.uuid);
      this.location = { ...location, ...data };

      this.loading = false;
      this.detailedLocationShowed();
    } catch (error) {
      // Exception manager
      this.notifyError({
        text: error.message,
        textAction: 'Ok',
      });
      this.closeModalComponent();
      this.trackPageView();
    }
  },

  methods: {
    printableAddress,
    compiledDescription(description = '') {
      return Marked(description, { sanitize: true, breaks: true });
    },
    detailedLocationShowed() {
      this.trackSegment({
        name: SEGMENT_EVENTS.LOCATION_DETAILS_VIEWED,
        data: {
          [SEGMENT_PARAM_NAMES.BOOKING_TYPE]: this.isLongDistance
            ? SEGMENT_ENUMERATE_VALUES.BOOKING_TYPE.LONG_DISTANCE
            : SEGMENT_ENUMERATE_VALUES.BOOKING_TYPE.CAR_SHARING,
        },
      });
    },
    url(src) {
      return src.replace(/%7C/g, '|');
    },
  },
};

</script>
