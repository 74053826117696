import isNull from 'lodash/isNull';
import trim from 'lodash/trim';
import get from 'lodash/get';
import isString from 'lodash/isString';
import isUndefined from 'lodash/isUndefined';
import startsWith from 'lodash/startsWith';
import keys from 'lodash/keys';
import inRange from 'lodash/inRange';

import googleMapsLoader from '@/vue/managers/GoogleMapsLoader';
import MarkerClusterer from '@/assets/js/MarkerClusterer';
import { MARKER_STATES } from '@/constants/map.const';
import { instance as mapMarkerSelector } from '@/services/MapMarkerSelector';
import { normalizeCoordinates } from '@/utils/mapsHelpers';
import { COLORS, ICONS } from '@emobg/web-components';

export const ZOOM_LEVEL_TO_VIEW_MARKER = 15;

export class MapManager {
  constructor(mapInstance) {
    this.map = mapInstance || null;
    this.markerList = {};
    this.markerCluster = null;
    this.route = null;
    this.lastMarkerSelected = null;
    this.lastInfoWindowOpened = null;
    this.lastTimeInfoWindowOpened = null;
    this.mapboundIsChanging = false;
    this.zoomMapIsChanging = false;
    this.mapIsLoaded = false;

    this.zoomLevelToShowCityMarkers = 9;
    this.zoomLevelWhenClickOnCityMarker = 17;

    this.zone = null;
    this.markerZone = null;
  }

  static getGoogleMapsLatLong(lat, lng) {
    const google = googleMapsLoader.getInstance();

    const { latitude, longitude } = normalizeCoordinates(lat, lng);

    return new google.maps.LatLng(latitude, longitude);
  }

  getMap() {
    return this.map;
  }

  setMap(mapInstance) {
    this.map = mapInstance;
  }

  getMapCenterLatitude() {
    return this.map.getCenter().lat();
  }

  getMapCenterLongitude() {
    return this.map.getCenter().lng();
  }

  getMarkerList() {
    return this.markerList;
  }

  getUserMarker() {
    return this.markerList.user;
  }

  getZone() {
    return this.zone;
  }

  getMarkerZone() {
    return this.markerZone;
  }

  getGeocompleteMarker() {
    return get(this, 'markerList.geocomplete');
  }

  createGeocompleteMarker({ lat, lng }) {
    const icon = MapManager.getSvgIconUrl(trim(mapMarkerSelector.iconPointer()));

    return this.createMarker({
      id: 'geocomplete',
      lat,
      long: lng,
      icon,
      zIndex: 10000000,
    });
  }

  createUserMarker({ lat, lng }) {
    const google = googleMapsLoader.getInstance();

    return this.createMarker({
      id: 'user',
      lat,
      long: lng,
      lng,
      icon: {
        url: mapMarkerSelector.iconUser(),
        anchor: new google.maps.Point(52, 52),
      },
      zIndex: 80000000,
      visible: false,
    });
  }

  setMarkerList(list) {
    this.markerList = list;
  }

  setMarkerZone(marker) {
    this.markerZone = marker;
  }

  getMarkerCluster() {
    return this.markerCluster;
  }

  setMarkerCluster(cluster) {
    this.markerCluster = cluster;
  }

  getRoute() {
    return this.route;
  }

  setRoute(route) {
    this.route = route;
  }

  getLastMarkerSelected() {
    return this.lastMarkerSelected;
  }

  setLastMarkerSelected(marker) {
    const lastMarker = this.getLastMarkerSelected();

    if (lastMarker) {
      let icon;

      if (lastMarker.defaultIcon) {
        // When marker has a default icon, different icon used in New Booking page
        icon = lastMarker.defaultIcon;
      } else {
        // Default icon. It's used in New Booking page
        const vehiclesAvailable = get(lastMarker, 'data.vehicles_available');
        const vehicleCount = isUndefined(vehiclesAvailable)
          ? null
          : vehiclesAvailable;

        const svg = MapManager.getSvgMarker(
          vehicleCount,
          MARKER_STATES.default,
          get(lastMarker, 'data.open', false),
          get(lastMarker, 'data.one_way_allowed', false),
        );
        icon = MapManager.getSvgIconUrl(svg);
      }
      lastMarker.setIcon(icon);
    }

    if (marker) {
      let icon;

      if (marker.hoverIcon) {
        // When marker has a default icon, different icon used in New Booking page
        icon = marker.hoverIcon;
      } else {
        // Default hover icon. It's used in New Booking page
        const vehiclesAvailable = get(marker, 'data.vehicles_available');
        const vehicleCount = isUndefined(vehiclesAvailable)
          ? null
          : vehiclesAvailable;
        const svg = MapManager.getSvgMarker(
          vehicleCount,
          MARKER_STATES.hover,
          get(marker, 'data.open', false),
          get(marker, 'data.one_way_allowed', false),
        );
        icon = MapManager.getSvgIconUrl(svg);
      }

      marker.setIcon(icon);
    }

    this.lastMarkerSelected = marker;

    this.closeLastInfoWindows();
  }

  closeLastInfoWindows() {
    const lastInfoWindow = this.getLastInfoWindowOpened();
    const lastTimeInfoWindow = this.getLastTimeInfoWindowOpened();

    if (lastInfoWindow) {
      lastInfoWindow.close();
      this.setLastInfoWindowOpened(null);
    }

    if (lastTimeInfoWindow) {
      lastTimeInfoWindow.close();
      this.setLastTimeInfoWindowOpened(null);
    }
  }

  getLastInfoWindowOpened() {
    return this.lastInfoWindowOpened;
  }

  setLastInfoWindowOpened(infoWindow) {
    const lastInfoWindow = this.getLastInfoWindowOpened();

    if (lastInfoWindow) {
      lastInfoWindow.close();
    }

    this.lastInfoWindowOpened = infoWindow;
  }

  getLastTimeInfoWindowOpened() {
    return this.lastTimeInfoWindowOpened;
  }

  setLastTimeInfoWindowOpened(infoWindow) {
    const lastTimeInfoWindow = this.getLastTimeInfoWindowOpened();

    if (lastTimeInfoWindow) {
      lastTimeInfoWindow.close();
    }

    this.lastTimeInfoWindowOpened = infoWindow;
  }

  isMapboundChanging() {
    return this.mapboundIsChanging;
  }

  isZoomMapChanging() {
    return this.zoomMapIsChanging;
  }

  toggleZoomMap(value) {
    let newValue = !this.zoomMapIsChanging;

    if (value === true || value === false) {
      newValue = value;
    }

    this.zoomMapIsChanging = newValue;
  }

  toggleMapbound(value) {
    let newValue = !this.mapboundIsChanging;

    if (value === true || value === false) {
      newValue = value;
    }

    this.mapboundIsChanging = newValue;
  }

  async setCenterMapAndZoom(lat, long) {
    if (this.map) {
      this.map.panTo(await MapManager.getGoogleMapsLatLong(lat, long));
      this.map.setZoom(this.getZoomLevelWhenClickOnCityMarker());
    }
  }

  setCenterAndZoomMap({ lat, lng }, zoom) {
    if (this.map) {
      this.map.panTo(MapManager.getGoogleMapsLatLong(lat, lng));
      this.map.setZoom(zoom);
    }
  }

  async setCenterMap(lat, lng) {
    if (this.map) {
      const zoomCenter = await MapManager.getGoogleMapsLatLong(lat, lng);
      this.map.panTo(zoomCenter);
    }
  }

  addMarker(marker) {
    this.markerList[marker.id] = marker;

    return marker;
  }

  showMarker(param) {
    let marker;

    if (isString(param)) {
      // when param is a marker ID
      const id = param;
      marker = this.markerList[id];

      if (!marker) {
        return;
      }
    } else {
      // when param is a Marker
      marker = param;
    }

    if (!marker.getMap()) {
      marker.setMap(this.map);
    }
    marker.setVisible(true);
  }

  addAndShowMarker(marker) {
    const markerObj = this.addMarker(marker);

    this.showMarker(markerObj.id);
  }

  createMarker(markerOptions) {
    const google = googleMapsLoader.getInstance();

    const newMarkerOptions = { id: `id_${(Math.random() * 100000).toFixed()}`,
      title: '',
      icon: '',
      animation: null,
      data: {},
      ...markerOptions };

    // When Marker ID exists, return that marker
    const marker = this.markerList[newMarkerOptions.id];

    if (marker) {
      return marker;
    }

    // When Marker ID doesn't exist, create a Position and a new Marker
    const { lat } = newMarkerOptions;
    const lng = newMarkerOptions.long || newMarkerOptions.lng;
    newMarkerOptions.position = MapManager.getGoogleMapsLatLong(lat, lng);

    return new google.maps.Marker(newMarkerOptions);
  }

  showAllMarkers() {
    Object
      .keys(this.markerList)
      .forEach(markerId => {
        this.showMarker(markerId);
      });
  }

  showAllLocationMarkers() {
    this.getLocationMarkers()
      .forEach(marker => {
        this.showMarker(marker.id);
      });
  }

  hideMarker(param) {
    let marker;

    if (isString(param)) {
      // when param is a marker ID
      const id = param;
      marker = this.markerList[id];

      if (!marker) {
        return;
      }
    } else {
      // when param is a Marker
      marker = param;
    }

    marker.setVisible(false);
  }

  hideZone() {
    if (this.getZone()) {
      this.getZone().setVisible(false);
      this.getZone().setMap(null);
    }

    if (this.getMarkerZone()) {
      this.showMarker(this.getMarkerZone());
      this.setMarkerZone(null);
    }
  }

  removeCityMarkers() {
    const markerKeys = keys(this.markerList);
    const cityMarkersKeys = markerKeys.filter(markerKey => startsWith(markerKey, 'city_'));
    cityMarkersKeys.forEach(cityMarkerKey => {
      const marker = this.markerList[cityMarkerKey];
      if (marker) {
        marker.setVisible(false);
      }
    });
  }

  showZone() {
    if (this.zone) {
      this.zone.setMap(this.getMap());
      this.zone.setVisible(true);
    }
  }

  hideAllMarkers() {
    this.getLocationMarkers()
      .forEach(marker => {
        this.hideMarker(marker.id);
      });

    this.closeLastInfoWindows();
  }

  removeAllParkingMarkersFromList() {
    this.getLocationMarkers()
      .forEach(marker => {
        delete this.markerList[marker.id];
      });
  }

  showClusters() {
    this.createMarkerCluster();
  }

  hideClusters() {
    if (this.markerCluster) {
      this.markerCluster.clearMarkers();
    }
  }

  reloadClusters() {
    this.hideClusters();
    this.showClusters();
  }

  changeLocationMarker(markerId, lat, long) {
    const marker = this.markerList[markerId];

    if (!marker) {
      return;
    }

    marker.setPosition(MapManager.getGoogleMapsLatLong(lat, long));
  }

  drawRoute(markerSourceId, markerDestinationId) {
    const google = googleMapsLoader.getInstance();

    this.hideRoute();

    // Get markers
    const markerSource = this.markerList[markerSourceId];
    const markerDestination = this.markerList[markerDestinationId];

    if (!(markerSource && markerDestination)) {
      return;
    }

    // Create request object
    const request = {
      origin: {
        lat: markerSource.getPosition().lat(),
        lng: markerSource.getPosition().lng(),
      },
      destination: {
        lat: markerDestination.getPosition().lat(),
        lng: markerDestination.getPosition().lng(),
      },
      travelMode: google.maps.TravelMode.WALKING,
    };

    // Create line
    const route = new google.maps.DirectionsRenderer({
      map: this.map,
      polylineOptions: {
        strokeColor: '#037B00',
        strokeWeight: 6,
      },
      suppressMarkers: true,
      preserveViewport: false,
    });

    this.setRoute(route);

    this.drawLineAndDirection(request, google);
  }

  drawLineAndDirection(request, google) {
    // Create petition to render line and render direction
    const directionsService = new google.maps.DirectionsService();
    directionsService.route(request, (response, status) => {
      if (status === google.maps.DirectionsStatus.OK && this.route) {
        // Display the route on the map.
        this.route.setDirections(response);
      }
    });
  }

  drawMultiRoute(origin, destination, wayPoints) {
    const google = googleMapsLoader.getInstance();

    this.hideRoute();

    // Create request object
    const request = {
      origin: MapManager.getGoogleMapsLatLong(origin.lat, origin.lng),
      destination: MapManager.getGoogleMapsLatLong(destination.lat, destination.lng),
      waypoints: wayPoints.map(i => ({
        location: MapManager.getGoogleMapsLatLong(i.lat, i.lng),
        stopover: true,
      })),
      travelMode: google.maps.TravelMode.WALKING,
    };

    // Create line
    const route = new google.maps.DirectionsRenderer({
      map: this.map,
      suppressMarkers: true,
      preserveViewport: false,
    });

    this.setRoute(route);

    this.drawLineAndDirection(request, google);
  }

  hideRoute() {
    // If there is any Route drew, remove it
    if (this.route !== null) {
      this.route.setMap(null);
      this.setRoute(null);
    }
  }

  isVisibleOnMap(lat, lng) {
    const position = MapManager.getGoogleMapsLatLong(lat, lng);

    return this.getMap().getBounds().contains(position) !== false;
  }

  isMapShowingCityIcons() {
    const mapZoom = this.getMap() && this.getMap().getZoom();
    const zoomToShowCityIcons = this.getZoomLevelToShowCityMarkers();

    return mapZoom <= zoomToShowCityIcons;
  }

  zoomOutUntilShowCityIcons() {
    if (this.getMap()) {
      this.getMap().setZoom(this.getZoomLevelToShowCityMarkers());
    }
  }

  static getSvgMarker(vehicleCount, markerType, fleet, oneWayAllowed = false) {
    const isOpenFleet = Boolean(fleet);
    const isDedicatedFleet = !isOpenFleet;

    const color = COLORS.primary;

    const marker = {
      default: markerType === MARKER_STATES.default,
      hover: markerType === MARKER_STATES.hover,
      zone: markerType === MARKER_STATES.geofence,
    };

    const useCases = {
      isOneWay: oneWayAllowed && marker.default,
      isOneWayHover: oneWayAllowed && marker.hover,
      isZoneOneWay: oneWayAllowed && marker.zone,
      isBusiness: isDedicatedFleet && marker.default,
      isBusinessHover: isDedicatedFleet && marker.hover,
      isZoneBusiness: isDedicatedFleet && marker.zone,
      isParking: isOpenFleet && marker.default,
      isParkingHover: isOpenFleet && marker.hover,
      isZoneParking: isOpenFleet && marker.zone,
    };

    const getMarkerForVehicleCount = (disabledCb, oneDigitCb, twoDigitCb, noAvailabilityCb = () => null) => {
      if (vehicleCount === 0) {
        return disabledCb();
      }
      if (inRange(vehicleCount, 1, 9)) {
        return oneDigitCb();
      }
      if (vehicleCount > 10) {
        return twoDigitCb();
      }
      if (isNull(vehicleCount)) {
        return noAvailabilityCb();
      }

      return null;
    };

    return this.getSvgByUseCases(useCases, getMarkerForVehicleCount, color, vehicleCount);
  }

  static getSvgByUseCases(useCases, getMarkerForVehicleCount, color, vehicleCount) {
    let svg = null;

    if (useCases.isOneWay || useCases.isZoneOneWay) {
      svg = getMarkerForVehicleCount(
        () => mapMarkerSelector.disabledOneWay(color),
        () => mapMarkerSelector.oneDigitOneWay(color, vehicleCount),
        () => mapMarkerSelector.twoDigitsOneWay(color, vehicleCount),
        () => mapMarkerSelector.oneWay(),
      );
    } else if (useCases.isOneWayHover) {
      svg = getMarkerForVehicleCount(
        () => mapMarkerSelector.disabledHoverOneWay(color),
        () => mapMarkerSelector.oneDigitHoverOneWay(color, vehicleCount),
        () => mapMarkerSelector.twoDigitsHoverOneWay(color, vehicleCount),
        () => mapMarkerSelector.oneWay(),
      );
    } else if (useCases.isBusiness || useCases.isZoneBusiness) {
      svg = getMarkerForVehicleCount(
        () => mapMarkerSelector.disabledBusiness(color),
        () => mapMarkerSelector.oneDigitBusiness(color, vehicleCount),
        () => mapMarkerSelector.twoDigitsBusiness(color, vehicleCount),
      );
    } else if (useCases.isBusinessHover) {
      svg = getMarkerForVehicleCount(
        () => mapMarkerSelector.disabledHoverBusiness(color),
        () => mapMarkerSelector.oneDigitHoverBusiness(color, vehicleCount),
        () => mapMarkerSelector.twoDigitsHoverBusiness(color, vehicleCount),
        () => mapMarkerSelector.iconBusinessBrand(),
      );
    } else if (useCases.isParking || useCases.isZoneParking) {
      svg = getMarkerForVehicleCount(
        () => mapMarkerSelector.disabledBrand(color),
        () => mapMarkerSelector.oneDigitBrand(color, vehicleCount),
        () => mapMarkerSelector.twoDigitsBrand(color, vehicleCount),
      );
    } else if (useCases.isParkingHover) {
      svg = getMarkerForVehicleCount(
        () => mapMarkerSelector.disabledHoverBrand(color),
        () => mapMarkerSelector.oneDigitHoverBrand(color, vehicleCount),
        () => mapMarkerSelector.twoDigitsHoverBrand(color, vehicleCount),
      );
    }

    return trim(svg);
  }

  static getSvgIconUrl(svg) {
    return {
      url: `data:image/svg+xml;charset=UTF-8;base64,${btoa(svg)}`,
    };
  }

  getLocationMarkers() {
    return Object.values(this.getMarkerList()).filter(marker => marker.id.startsWith('location_'));
  }

  createMarkerCluster() {
    const thereAreMarkers = Object.values(this.getMarkerList()).length > 0;
    const clusterIsNotDefined = !!this.getMarkerCluster() === false;

    this.showAllLocationMarkers();

    this.getLocationMarkers().forEach(m => {
      m.infoWindows.location.close();
      m.infoWindows.time.close();
    });

    if (thereAreMarkers && clusterIsNotDefined) {
      const markers = this.getLocationMarkers();

      const markerClusterOptions
        = {
          imagePath: mapMarkerSelector.cluster(),
          custom: {
            color: 'white',
            'font-family': '\'NEXTBook-Medium\', sans-serif',
            'background-position': 'center center',
            'background-repeat': 'no-repeat',
            'font-size': '20px',
            'font-weight': '600',
          },
        };

      this.setMarkerCluster(new MarkerClusterer(this.getMap(), markers, markerClusterOptions));
    }

    this.getMarkerCluster().repaint();
  }

  removeMarkerCluster() {
    if (this.getMarkerCluster()) {
      this.closeLastInfoWindows();

      this.setLastMarkerSelected(null);

      this.hideZone();

      this.setMarkerZone(null);

      this.hideRoute();

      this.hideAllMarkers();

      this.getMarkerCluster().clearMarkers();

      this.setMarkerCluster(null);
    }
  }

  getZoomLevelToShowCityMarkers() {
    return this.zoomLevelToShowCityMarkers;
  }

  getZoomLevelWhenClickOnCityMarker() {
    return this.zoomLevelWhenClickOnCityMarker;
  }

  resizeMap() {
    const google = googleMapsLoader.getInstance();

    google.maps.event.trigger(this.map, 'resize');
  }

  createZone(coordinates) {
    if (!this.zone) {
      const google = googleMapsLoader.getInstance();
      this.zone = new google.maps.Polygon({
        map: this.getMap(),
        paths: coordinates,
        visible: false,
      });
    } else {
      this.zone.setPath(coordinates);
    }
  }

  static createInfoWindow(marker) {
    const google = googleMapsLoader.getInstance();

    const templateInfoWindow
      = `<div class="MapInfoWindow">
          <div class="MapInfoWindow__content d-flex emobg-color-ink justify-content-center align-items-center">
              ${marker.title}
          </div>
          <div class="MapInfoWindow__separator"></div>
          <div class="MapInfoWindow__icon">
            <ui-icon icon="${ICONS.infoCircle}" color="${COLORS.primary}" alt="info icon"/>
          </div>
      </div>`;

    return new google.maps.InfoWindow({
      content: trim(templateInfoWindow),
      zIndex: 100,
    });
  }

  static howManyTime(marker, locationMarker) {
    const google = googleMapsLoader.getInstance();

    // To know how many meters user has to walk to
    // arrive to his destination / parking
    const distanceMatrixService = new google.maps.DistanceMatrixService();

    const request = {
      origins: [marker.getPosition()],
      destinations: [locationMarker.getPosition()],
      travelMode: google.maps.TravelMode.WALKING,
      unitSystem: google.maps.UnitSystem.METRIC,
    };

    return new Promise((resolve, reject) => {
      distanceMatrixService.getDistanceMatrix(
        request,
        response => resolve(response),
        response => reject(response),
      );
    });
  }

  static createInfoWindowTime(time) {
    const google = googleMapsLoader.getInstance();

    const templateInfoWindow
      = `<div class="MapTimeInfoWindow">
          <div class="MapTimeInfoWindow__content d-flex justify-content-center align-items-center">
              ${time}
          </div>
      </div>`;

    return new google.maps.InfoWindow({
      content: trim(templateInfoWindow),
      zIndex: 95,
    });
  }

  static createInfoWindowVehicleLocation(text) {
    const google = googleMapsLoader.getInstance();

    const templateInfoWindow
      = `<div class="MapVehicleLocationInfoWindow">
          <div class="MapTimeInfoWindow__content">
              ${text}
          </div>
      </div>`;

    return new google.maps.InfoWindow({
      content: trim(templateInfoWindow),
      zIndex: 95,
    });
  }
}

export const mapManager = new MapManager();
