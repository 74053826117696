<template>
  <div>
    <ui-modal
      :open="isOpen"
      :header="$t('modal.upgrade_insurance.title')"
      :size="editPSD2PaymentVisible ? SIZES.medium : SIZES.small"
      data-test-id="booking_insurance-modal"
    >
      <div slot="body">
        <AlertComponent
          v-if="errorMessage"
          :type="ALERT_TYPES.danger"
          class="w-100 mb-3"
          data-test-id="alert-error"
        >
          {{ errorMessage }}
        </AlertComponent>

        <PaymentMethodsHub
          v-if="editPSD2PaymentVisible"
          class="border-bottom pb-2 mb-2"
          :action="ACTIONS.select"
          :provider="adyenProviderVersion"
          :select-locked="selectLocked"
          :handle-external-response="PaymentResponse"
          @update:helper-component-active="setIsPaymentValid"
          @update:payment-method-valid="onPaymentMethodValid"
          @on:payment-error="setErrorMsg"
        />

        <div
          class="d-flex flex-column"
          data-test-id="modal-title"
        >
          <div class="emobg-font-small">
            <p>
              {{ $t('modal.upgrade_insurance.subtitle') }}
              <br>
              {{ $t('modal.upgrade_insurance.paragraph') }}
            </p>
          </div>
          <div class="text-left p-3">
            <div class="row">
              <div class="emobg-font-x-small pl-0 text-left col-12">
                {{ $t('modal.upgrade_insurance.summary_title') }}
              </div>
            </div>
            <div class="row emobg-font-small border-top border-bottom">
              <div class="col-10 px-0 py-2">
                {{ $t('modal.upgrade_insurance.summary_line') }}
              </div>
              <div class="col-2 px-0 py-2 pl-3">
                {{ price }}
              </div>
            </div>
            <div class="row pt-2">
              <div class="col-10 pl-0 emobg-font-medium emobg-font-weight-bold">
                {{ $t('modal.upgrade_insurance.summary_footer') }}:
              </div>
              <div class="col-2 emobg-font-medium emobg-font-weight-bold pl-2">
                {{ price }}
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        slot="footer"
        class="d-flex justify-content-end"
      >
        <ui-button
          v-bind="fetchButtonSpecs({ buttonType: THEME_BUTTON_TYPES.SECONDARY })"
          data-test-id="cancel-button"
          class="mr-2"
          @clickbutton="closeModal({ isEdited: false })"
        >
          {{ $t('buttons.cancel') }}
        </ui-button>
        <ui-button
          v-bind="fetchButtonSpecs()"
          data-test-id="confirm-button"
          :loading="isButtonUpgradeLoading"
          :disabled="isButtonUpgradeDisabled"
          @clickbutton="editBookingSubmit"
        >
          {{ $t('buttons.confirm') }}
        </ui-button>
      </div>
    </ui-modal>
    <FeedbackModalComponent
      v-model="modals.active.isOpen"
      v-bind="modals.active"
    />
  </div>
</template>

<script>
// Utils
import get from 'lodash/get';
import find from 'lodash/find';

import { external } from '@emobg/web-api-client';
import {
  camelCaseKeys, isNullValue, LOG_TYPE, logger, navigationErrorHandler,
} from '@emobg/web-utils';
import { mapActions, mapState } from 'vuex';
import { parseApiErrorMessage } from '@/utils/apiHelpers';

// Components
import FeedbackModalComponent from '@Shared/components/FeedbackModal/FeedbackModalComponent';
import AlertComponent from '@/components/Alert/AlertComponent';
import ALERT_TYPES from '@/components/Alert/alertTypes';

// Services
import Api from '@/vue/api/backoffice';

// Mixins
import EventHandlerMixin from '@/mixins/EventHandler';

// Stores
import { getCurrentCSOperator } from '@/stores/CSOperator/CSOperatorMapper';
import { userData } from '@/stores/User/UserData/UserDataMapper';
import { nameSpace as InsurancesModuleNameSpace } from '@/stores/Insurances/InsurancesModule';
import { getUpgradeInsuranceInfo, upgradeInsuranceInfo } from '@/stores/Booking/BookingMapper';

// Constants
import { INSURANCE_TYPES } from '@/constants/insuranceTypes.const';
import { BOOKING_EVENTS } from '@Bookings/MyBookings/constants/event-handlers';
import { SEGMENT_EVENTS } from '@/constants/segment';

// Routes names
import personalProfilesRoutesNames from '@Profile/Personal/router/routes-names';
import { errorNotification } from '@/handlers/errorHandler.const';
import { useSegment } from '@/composable/Segment/segment';
import { useNotifications } from '@/composable/App/Notifications/useNotifications';

import { useTheme } from '@/composable/Theme/useTheme';
import { BOOKING_TYPES } from '@/constants/bookingTypes';

import PaymentMethodsHub from '@Shared/Payments/PaymentMethodsHub/PaymentMethodsHub';
import {
  ACTIONS, ADYEN_VERSION_THRESHOLD, DEFAULT_PAYMENT_PROVIDER, EDIT_BOOKING_VARIANT, HUB_REDIRECT_FLOW_STATUS, RESPONSE_STATUS, SOURCE_PAGES,
} from '@Shared/Payments/PaymentMethodsHub/constants/paymentMethodsHub';

import { commonImplementationHelper } from '@Shared/Payments/PaymentMethodsHub/commonImplementationHelper';

import { PaymentMethodsHubComposable } from '@Shared/Payments/PaymentMethodsHub/composables/PaymentMethodsHubComposable';

import { removeUrlQuery } from '@Shared/Payments/PaymentMethodsHub/composables/responseUtils';

import { errorArgs, successArgs } from './constants/modalsArguments';

export default {

  components: {
    FeedbackModalComponent,
    AlertComponent,

    PaymentMethodsHub,
  },

  mixins: [
    EventHandlerMixin,
  ],

  inject: ['paymentSuccess'],

  props: {
    isOpen: {
      type: Boolean,
      default: false,
    },

    booking: {
      type: Object,
      required: true,
    },

    selectLocked: {
      type: Boolean,
      default: false,
    },
  },

  setup(props, context) {
    const { fetchButtonSpecs } = useTheme();
    const { trackSegment } = useSegment();
    const { notifyError } = useNotifications();

    const { booking } = props;

    const {
      bookingUuid,
      usage_status_key: usageStatus,
      cs_booking_use_case: csBookingUseCase,
    } = booking;

    const fleetType = booking.fleet || csBookingUseCase.fleet;

    const bookingType = csBookingUseCase.booking_type || BOOKING_TYPES.carsharing;

    const {
      checkPaymentIsFinalStatus, isBusinessProfile, setNewResponse, storeData,
    } = commonImplementationHelper(context);

    const { getAPIFromProvider } = PaymentMethodsHubComposable(storeData);

    const providerVersionRegex = /\d/;

    return {
      fetchButtonSpecs,
      notifyError,
      trackSegment,

      bookingUuid,
      usageStatus,
      fleetType,
      bookingType,

      checkPaymentIsFinalStatus,
      getAPIFromProvider,
      isBusinessProfile,
      providerVersionRegex,
      setNewResponse,
    };
  },

  data() {
    return {
      price: null,
      isUpgradeLoading: false,
      isUpgradeDisabled: true,
      annualInsurancePrice: null,
      fullInsuranceUuid: null,
      routes: {
        personalProfile: {
          ...personalProfilesRoutesNames,
        },
      },
      modals: {
        active: {
          isOpen: false,
          primaryCallToAction: () => {
            this.modals.active.isOpen = false;
            this.closeModal({ isEdited: true });
            this.resetActiveBookings();
          },
        },
      },
      errorMessage: null,
      isPaymentMethodValid: false,
      currentPaymentMethod: null,
      PaymentResponse: null,
      isPaymentLoading: false,
    };
  },

  computed: {
    ...mapState(InsurancesModuleNameSpace, ['insurances']),
    upgradeInsuranceInfo,
    userData,

    adyenProviderVersion() {
      return this.$featureFlag.flags.csrev3524EditBooking ? 'Adyen/5.59.0' : DEFAULT_PAYMENT_PROVIDER;
    },

    getProviderVersion() {
      const version = this.providerVersionRegex.exec(this.adyenProviderVersion)[0];

      return Number(version) || null;
    },

    AdyenVersionAboveThreshold() {
      return this.getProviderVersion && this.getProviderVersion > ADYEN_VERSION_THRESHOLD;
    },

    isButtonUpgradeDisabled() {
      return this.editPSD2PaymentVisible ? !this.isPaymentMethodValid : this.isUpgradeDisabled;
    },

    isButtonUpgradeLoading() {
      return this.editPSD2PaymentVisible ? this.isPaymentLoading : this.isUpgradeLoading;
    },

    editPSD2PaymentVisible() {
      return this.AdyenVersionAboveThreshold && !this.isBusinessProfile;
    },
  },

  watch: {
    paymentSuccess({ response }) {
      this.setIsPaymentLoading(!this.checkPaymentIsFinalStatus(response));
    },
  },

  async created() {
    this.ALERT_TYPES = ALERT_TYPES;
    this.ACTIONS = ACTIONS;

    await this.getUpgradeInsuranceData();

    const csOperatorWorkingHours = get(this.getCurrentCSOperator(), 'customer_service_opening_hours');
    const csOperatorPhone = get(this.getCurrentCSOperator(), 'customer_service_phone');
    this.successModal = successArgs(this.$t);
    this.errorModal = errorArgs(this.$t, csOperatorWorkingHours, csOperatorPhone);

    this.isUpgradeDisabled = false;
  },

  methods: {
    ...mapActions(InsurancesModuleNameSpace, ['getInsurances']),
    getUpgradeInsuranceInfo,
    getCurrentCSOperator,

    trackEvent(name) {
      const {
        bookingUuid,
        bookingType,
        fleetType,
        usageStatus,
        errorMessage,
      } = this;

      this.trackSegment({
        name,
        data: {
          bookingUuid,
          bookingType,
          fleetType,
          errorMessage,
          usageStatus,
        },
      });
    },

    async getUpgradeInsuranceData() {
      try {
        await this.getUpgradeInsuranceInfo({ bookingUuid: this.bookingUuid });
        await this.getInsurances({ user_uuid: this.userData.uuid });

        const annualInsurance = find(this.insurances.data, ['internal_name', INSURANCE_TYPES.annualCarsharing]);
        this.annualInsurancePrice = get(annualInsurance, 'price');
        this.price = get(this.upgradeInsuranceInfo, 'price');
        this.fullInsuranceUuid = get(this.upgradeInsuranceInfo, 'uuid');
      } catch (error) {
        this.notifyError(errorNotification(error));
        this.closeModal({ isEdited: false });
      }
    },

    async upgradeInsurance() {
      this.errorMessage = null;
      this.isUpgradeLoading = true;

      try {
        await external.booking.putUpdateInsurance(
          this.bookingUuid,
          { insurance_uuid: this.fullInsuranceUuid },
        );
        this.modals.active = { ...this.modals.active, ...this.successModal, isOpen: true };
      } catch (error) {
        this.errorMessage = parseApiErrorMessage(this.$t, this.$i18n, error);
        logger.message(`There was a problem trying to upgrade insurance booking: ${this.errorMessage}`, LOG_TYPE.error);
        this.modals.active = { ...this.modals.active, ...this.errorModal, isOpen: true };
      } finally {
        this.trackEvent(SEGMENT_EVENTS.upgradeInsurance);
      }
      this.isUpgradeLoading = false;
    },

    resetActiveBookings() {
      Api.carsharing.activeBookingsRequests = { params: null, time: null, request: null };
      this.eventHandler.$emit(BOOKING_EVENTS.submitEditBooking);
      this.eventHandler.$emit(this.events.UPDATE_ACTIVE_BOOKINGS, { forceUpdate: true });
    },

    closeModal({ isEdited = false }) {
      this.$emit('on:close', { isEdited });
    },

    onPaymentMethodValid(method) {
      this.setIsPaymentValid(method.isValid);
      this.setCurrentPaymentMethod(method);
      this.setErrorMsg(null);
    },

    setErrorMsg(message) {
      this.errorMessage = message;
    },

    setIsPaymentValid(isValid) {
      this.isPaymentMethodValid = isValid;
    },

    setIsPaymentLoading(isLoading) {
      this.isPaymentLoading = isLoading;
    },

    setCurrentPaymentMethod(method) {
      this.currentPaymentMethod = method;
    },

    setIsUpgradeLoading(isLoading) {
      this.isUpgradeLoading = isLoading;
    },

    editBookingSubmit() {
      if (this.editPSD2PaymentVisible) {
        this.submitPayment();
      } else {
        this.upgradeInsurance();
      }
    },

    async submitPayment() {
      if (!this.editPSD2PaymentVisible) {
        return;
      }

      this.setErrorMsg(null);
      this.setIsUpgradeLoading(true);

      const { isValid } = this.currentPaymentMethod;

      if (!isValid) {
        return;
      }

      this.setIsPaymentLoading(true);

      let originalResponse = {};

      const apiFromProvider = await this.getAPIFromProvider(this.adyenProviderVersion);

      try {
        const transformedBooking = camelCaseKeys(this.booking);

        const {
          start, end, user, tags, destinationLocation,
        } = transformedBooking;

        const userUuid = user.uuid;
        const destinationLocationUuid = destinationLocation.uuid;

        const editBookingPayload = {
          userUuid,
          start,
          end,
          tags,
          insuranceUuid: this.fullInsuranceUuid,
          destinationLocationUuid,
        };

        const invoice = await apiFromProvider.putEditBookingPayment(this.bookingUuid, editBookingPayload);

        const { invoiceUuid, resourceType, resourceUuid } = invoice;

        const invoiceRequest = {
          ...this.currentPaymentMethod,
          paymentMethodUuid: this.currentPaymentMethod.paymentMethod.uuid,
          invoiceUuid,
          resourceType,
          resourceUuid,
        };

        originalResponse = await apiFromProvider.payInvoice(invoiceRequest);
      } catch (error) {
        this.errorMessage = parseApiErrorMessage(this.$t, this.$i18n, error);

        logger.message(`There was a problem trying to upgrade insurance booking: ${this.errorMessage}`, LOG_TYPE.error);
        this.modals.active = { ...this.modals.active, ...this.errorModal, isOpen: true };

        originalResponse.providerStatus = RESPONSE_STATUS.error;
        originalResponse.providerMessage = error.response.data.message;
      } finally {
        this.trackEvent(SEGMENT_EVENTS.upgradeInsurance);
      }

      removeUrlQuery(window.location.href, window.location.search);

      const response = camelCaseKeys(originalResponse);

      if (response.providerStatus === RESPONSE_STATUS.refused) {
        this.setErrorMsg(response.providerMessage);
      }

      if (
        response.providerStatus === RESPONSE_STATUS.pending
        && !this.$router.history.current.fullPath.includes(HUB_REDIRECT_FLOW_STATUS.toRedirect)
      ) {
        this.$router.push({ query: { status: HUB_REDIRECT_FLOW_STATUS.toRedirect } }).catch(navigationErrorHandler);
      }

      const matchingResponse = {
        ...response,
        matchingName: `${this.bookingUuid}${EDIT_BOOKING_VARIANT.splitter}${EDIT_BOOKING_VARIANT.editInsurance}`,
      };

      const hierarchy = {
        ancestor: SOURCE_PAGES.editBooking,
        parent: this.bookingUuid,
      };

      const finalResponse = this.setNewResponse(matchingResponse, response.providerStatus, hierarchy);

      if (isNullValue(finalResponse)) {
        this.setIsPaymentLoading(false);
      } else {
        this.PaymentResponse = finalResponse;
      }

      this.setIsUpgradeLoading(false);
    },
  },
};
</script>
