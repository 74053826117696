/**
 * Scrolls the viewport to the provided vertical position.
 *
 * @param {number} [top=0] - Distance from the top to scroll to.
 * @param {string} [behavior='smooth'] - Animation mode for scrolling.
 */
export const scrollTo = (top = 0, behavior = 'smooth') => {
  const app = document.getElementById('app');

  if (app) {
    app.scrollTo({
      top,
      behavior,
    });
  }
};
